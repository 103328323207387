<template>
    <div v-if="!postArray.error" @click="click">
        <div class="EntitySingle">
            <Header device-header="device" :language-visibility="false" />
            <div :class="(help ? 'hidden' : '')">
                <div class="mx-12 relative">
                    <div id="pixi" :class="(startComparison ? 'hidden' : '') + ' px-16 bg-bggt rounded-lg'"></div>
                    <div id="" class="px-16 bg-bggt rounded-lg flex align-center">
                    <ImgComparisonSlider :class="(!startComparison ? 'hidden' : '')">
                        <img
                        class="comparison_image"
                        slot="first"
                        :style="'width: ' + imgWidth + '; height: ' + imgHeight + ';'"
                        :src="postArray.before_img"
                        />
                        <img
                        class="comparison_image"
                        slot="second"
                        :style="'width: ' + imgWidth + '; height: ' + imgHeight + ';'"
                        :src="postArray.after_img"
                        /> 
                    </ImgComparisonSlider>
                    </div>
                    <div class="absolute right-5 bottom-6">
                        <BaseIcon v-if="showIcons && !startComparison" name='comparison' class="mb-6" @click="comparison" padding="p-1.5"/>
                        <BaseIcon v-if="showIcons && startComparison" name='comparisonWhite' class="mb-6" @click="comparisonStop" padding="p-1.5"/>
                        <BaseIcon v-if="showIcons" name='reset' @click="setPixi"/>
                    </div>
                </div>
            </div>
            <div :class="(!help ? 'hidden' : '') + ' grid grid-cols-3 mx-12 pt-16'" :height="windowHeight" :style="'height: ' + windowHeight + 'px;'"> 
                <div class="mx-4 p-8 text-white flex flex-col items-center" :style="'height: ' + (windowHeight - 64) + 'px;'">
                    <img classs="w-fit" style="max-width: 76%;" src="../../assets/scratchDescription.png" />
                    <h3 class="text-center text-5xl py-6" style="line-height: 62px;" >{{$t('scratch.title')}}</h3>
                    <p class="text-center text-sm leading-6" >{{$t('scratch.description')}}</p>
                </div>
                <div class="help help-first active mx-4 p-8 bg-bggt text-white rounded-2xl" @click="helpCardChange('help-first')">
                    <img class="w-full" src="../../assets/scratchPasul1.jpg" />
                    <h4 class="text-redDamask pt-4 text-2xl">{{$t('scratch.step1')}}</h4>
                    <h3 class="pt-4 text-4xl">{{$t('scratch.step1Title')}}</h3>
                    <p class="pt-4 text-sm">{{$t('scratch.step1Description')}}</p>
                </div>
                <div class="help help-second  mx-4 p-8 bg-bggt text-white rounded-2xl"  @click="helpCardChange('help-second')">
                    <img class="w-full" src="../../assets/scratchPasul2.jpg" />
                    <h4 class="text-redDamask pt-4 text-2xl">{{$t('scratch.step2')}}</h4>
                    <h3 class="pt-4 text-4xl">{{$t('scratch.step2Title')}}</h3>
                    <p class="pt-4 text-sm">{{$t('scratch.step2Description')}}</p>
                </div>
            </div>
            <div class="mx-12 my-20 flex justify-between">
                <div class="flex items-center">
                    <Button :text="$t('scratch.back')" class="pr-8" buttonType="tertiary" @click="this.$router.go(-1)"/>
                    <p class="text-white pr-8 overflow-hidden max-h-[70px]">{{postArray.entityDescription}}</p>
                </div>
                <div class="flex">
                    <Button :class="(help ? 'hidden' : '')" :text="$t('scratch.help')" buttonType="secondary" @click="startTutorial"/>
                    <Button :class="(!help ? 'hidden' : '')" :text="$t('scratch.startGame')" buttonType="primary" @click="startGame"/>
                    <!-- <Button :text="$t('scratch.next')" class="pl-4" buttonType="primary"/> -->
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h1>ERROR! ELEMENT NOT FOUND!</h1>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import * as PIXI from 'pixi.js'
// import BackToList from "@/components/tablet/BackToList.vue";
// import Navigation from "@/components/tablet/Navigation.vue";
// import Gallery from '@/components/Gallery.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import Button from "@/components/Button.vue";
import { ImgComparisonSlider } from '@img-comparison-slider/vue';

export default {
	name: 'EntitySingle',
	components: {
		// BackToList,
		Header,
		// Navigation,
		// Gallery,
        BaseIcon,
        Button,
        ImgComparisonSlider,
	},
	props: {
		content: {
            type: Object,
            require: true,
            default: () => {}
		},
		device: {
			type: String,
			require:false,
		}
	},
	data(){
        return {
            app: null,
            brush: null,
            renderTexture: null,
            dragging: false,
            showIcons: false,
            startComparison: false,
            windowWidth: window.innerWidth * 0.7,
			windowHeight: window.innerHeight * 0.7,
            help: true,
            imgWidth: 'auto',
            imgHeight: '70vh',
            canvasWidth: 466
        }
    },
	computed: {
		postArray() {
			return {
				title: this.content?.title,
				text: this.content?.sport_content,
				src: this.content?.img,
				alt: this.content?.alt,
				description: this.content?.description,
				link: window.location.pathname + '/' + this.content?.id,
				error: this.content?.error,
				entity_id: this.content?.id,
				story: this.content?.story,
				after_img: this.content?.after_image,
				before_img: this.content?.before_image,
                entityDescription: this.content?.description
			}
		},
        src() {
            return require("../../assets/icons/comparisonHandler.svg")
        },
  	},
	mounted() {
        let shadowRoot = this.$el.querySelector('img-comparison-slider')?.shadowRoot;
        shadowRoot.querySelector('slot[name=handle]').innerHTML = '<img class="mx-auto my-auto cursor-pointer" src="' + this.src + '" >';
    },
	created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
	methods: {
        setPixi() {
            this.showIcons = false;
            this.startComparison = false;
            var canvas = document.getElementById('pixi');
            canvas.innerHTML = '';
            this.getMeta(this.content.before_image);
            this.app = new PIXI.Application({
                width: this.canvasWidth,
                height: 560,
            });
            canvas.appendChild(this.app.view);

            // prepare circle texture, that will be our brush
            this.brush = new PIXI.Graphics();
            this.brush.beginFill(0xffffff);
            this.brush.drawCircle(0, 0, 50);
            this.brush.endFill();
            // this.app.loader.add('t1', 'https://szmm.minic.dev/wp-content/uploads/2022/09/Default_AO.jpg');
            // this.app.loader.add('t2', 'https://szmm.minic.dev/wp-content/uploads/2022/09/f00a2781468e520e00c086cf6d87f1a3_Selfie_2022-09-13_08:54:52am.png');
            // console.log(this.content);
            this.app.loader.add('t1', this.content.before_image);
            this.app.loader.add('t2', this.content.after_image);
            this.app.loader.load(this.setup);
        },
        setup(loader, resources) {
            const background = new PIXI.Sprite(resources.t1.texture);
            this.app.stage.addChild(background);
            background.width = this.app.screen.width;
            background.height = this.app.screen.height;

            const imageToReveal = new PIXI.Sprite(resources.t2.texture);
            this.app.stage.addChild(imageToReveal);
            imageToReveal.width = this.app.screen.width;
            imageToReveal.height = this.app.screen.height;

            this.renderTexture = PIXI.RenderTexture.create(this.app.screen.width, this.app.screen.height);

            const renderTextureSprite = new PIXI.Sprite(this.renderTexture);
            this.app.stage.addChild(renderTextureSprite);
            imageToReveal.mask = renderTextureSprite;

            this.app.stage.interactive = true;

            this.app.stage.on('pointerdown', this.pointerDown);
            this.app.stage.on('pointerup', this.pointerUp);
            this.app.stage.on('pointermove', this.pointerMove);
        },
        pointerMove(event) {
            if (this.dragging) {
                this.showIcons = true;
                this.brush.position.copyFrom(event.data.global);
                this.app.renderer.render(this.brush, this.renderTexture, false, null, false);
            }
        },
        pointerDown(event) {
            this.dragging = true;
            this.pointerMove(event);
        },
        pointerUp(event) {
            this.dragging = false;
        },
        comparison() {
            this.startComparison = true;
        },
        comparisonStop () {
            this.startComparison = false;
        },
        startGame() {
            this.help = false;
            this.getMeta(this.content.before_image);
        },
        startTutorial() {
            this.help = true;
        },
        helpCardChange(activateClass) {
            const helpSection =  this.$el.querySelectorAll('.help');
            for (let i = 0; i < helpSection.length; i++) {
                helpSection.item(i).classList.remove('active');
            }
            this.$el.querySelectorAll('.' + activateClass).item(0).classList.toggle('active');
        },
        getMeta(url){   
            const img = new Image();
            img.src = url;
            if (img.naturalHeight != 0) {
                this.canvasWidth = Math.round(img.naturalWidth * 560 / img.naturalHeight);
            }
            console.log(this.canvasWidth);
            return this.canvasWidth;
        }
    },
    watch:{
        content(newVal,oldVal){
            this.setPixi();
            let shadowRoot = this.$el.querySelector('img-comparison-slider')?.shadowRoot;
            shadowRoot.querySelector('slot[name=handle]').innerHTML = '<img class="mx-auto my-auto cursor-pointer" src="' + this.src + '" >';
        },
        canvasWidth(newVal, oldVal) {
            if (newVal) {
                this.setPixi();
            }
        }        
    }, 
}
</script>

<style>

.description img,
.story img {
	margin: 0 auto !important;
}

canvas, img-comparison-slider{
    margin: auto;
}

.comparison_image:focus-visible {
    outline: none;
}

:focus-visible {
    outline:none;
}

.divider:after {
    content: "";
    display: block;
    height: 0%;
    border-left-width: var(--divider-width);
    border-left-style: solid;
    border-left-color: var(--divider-color);
    box-shadow: var(--divider-shadow);
}

img-comparison-slider {
    --divider-width: 0;
}

.help-second {
    transform: translate(3%, 0);
}
.help-first {
    transform: translate(-4%, 0);
}

.help.active {
    width: 106%;
    margin: 0;
    height: 112%;
    background-color: #FDF1D6;
    color: #261710 !important;
    transform: translate(-4%, -6%) !important;
}

</style>