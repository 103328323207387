<template>
  <div>
    <Game
      :content="element"
      device="tablet"
    />
  </div>
</template>

<script>

import Game from "@/components/tablet/Game.vue";

export default {
    name: 'TVGameDetail',
    components: {
        Game
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            element: {},
            id: this.$route.params.id,
            theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark',
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
        }
    },
    mounted() {
        this.$i18n.locale = this.lang;
        this.getElementById();
    },
    methods: {
        getElementById() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/tv/`+this.id, {}, {
            }).then(response => {
                this.element = response.data;
                console.log(this.element)
            }).catch( (error) => {
                // console.log(error);
            });
        },
    }
}
</script>
