<template>
    <div>
        <EntitySingle :content="element" device='tablet' />
    </div>
</template>

<script>

import EntitySingle from "@/components/tablet/EntitySingle.vue";

export default {
    name: 'TabletDetail',
    components: {
        EntitySingle
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            element: {},
            id: this.$route.params.id,
            theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark'
        }
    },
    mounted() {
        this.getElementById();
    },
    methods: {
        getElementById() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/tablet/`+this.id, {}, {
                headers: {
                        'Access-Control-Allow-Origin': '*'
                }
            }).then(response => {
                this.element = response.data;
            }).catch( (error) => {
                // console.log(error);
            });
        },
    }
}
</script>
